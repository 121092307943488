<template>
  <ais-instant-search 
    :search-client="searchClient" 
    :index-name="indexName"
  >
    <ais-search-box ref="searchbox" v-model="query" placeholder="Search..." />
    <ais-hits class="p-3" v-if="query.length > 0">
      <template v-slot:item="{ item }">
        <div class="p-1 hit-item d-flex">
          <img :src="item.image_url" class="img-fluid d-inline me-2" style="max-height: 50px; object-fit: contain;" />
          <h5 class="d-inline text-dark"><a :href="'/food/' + item.upc">{{ item.name }}</a></h5>
          <!-- Add other fields you want to display -->
        </div>
      </template>
    </ais-hits>
  </ais-instant-search>
</template>

<script>
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';
import InstantSearch from 'vue-instantsearch/vue2/es';
import Vue from 'vue';

// Register Vue InstantSearch
Vue.use(InstantSearch);

export default {
  name: 'Searchbar',
  data() {
    return {
        query: '',
      searchClient: instantMeiliSearch(
        'https://search.happyoilsapp.com',  // Your MeiliSearch host
        'OWYyMDMxYTkxYjAxN2UxNGUwNjc5OWU3', // Your MeiliSearch API key
      ).searchClient,
      indexName: 'food'
    };
  },
  methods: {
    setQuery(value) {
      this.query = value;
    }
  }
};
</script>

<style>
@import 'instantsearch.css/themes/satellite-min.css';

.hit-item {
  padding: 1rem;
  border-bottom: 1px solid #eee;
}
.ais-Hits-list {
    position: absolute;
    height: 500px;
    overflow: scroll;
    z-index: 99;
    box-shadow: 0px 0px 5px #b0b0b0;
}
.ais-Hits-item {
    padding: 15px;
}
.hit-item a {
    color: #000;
    font-size: 14px;
    text-decoration: none;
}
.ais-SearchBox {
    width: 485px;
}
</style>
